import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { MenuComponent } from './components/menu/menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BranchesComponent } from './components/branches/branches.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { CompanyComponent } from './components/company/company.component';
import { OurTeamComponent } from './components/our-team/our-team.component';
import { NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DynamicFormBuilderModule } from './dynamic-form-builder/dynamic-form-builder.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UsersComponent } from './components/users/users.component';
import { dashboardComponent } from './components/doshbord/dashboard.component';
import { ShowComponent } from './components/show/show.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SearchByNamePipe } from './Pipes/searchByName.pipe';
import { EmailComponent } from './components/email/email.component';
import {MatCheckboxModule} from '@angular/material/checkbox';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    BranchesComponent,
    CompanyComponent,
    OurTeamComponent,
    ChangePasswordComponent,
    UsersComponent,
    dashboardComponent,
    ShowComponent,
    SearchByNamePipe,
    EmailComponent
  ],
  imports: [
    BrowserModule,
    ModalModule.forChild(),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFormBuilderModule,
    HttpClientModule,
    ImageCropperModule,
    AngularEditorModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatCheckboxModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
